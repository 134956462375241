<template>
  <div class="wrapper">
    <nav v-if="user" class="main-header navbar navbar-expand navbar-dark navbar-info">
      <ul class="navbar-nav">
        <li class="nav-item"><a data-widget="pushmenu" @click="collapseMenu()" class="nav-link"><i
            class="fas fa-bars"></i></a></li>
      </ul>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown" :class="{'show': dropdown}">
          <a id="dropdownSubMenu1" @click="dropdown = !dropdown" data-toggle="dropdown" aria-haspopup="true"
             aria-expanded="false" class="nav-link dropdown-toggle">{{ user.login }}</a>
          <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu dropdown-menu-right border-0 shadow"
              :class="{'show': dropdown}">
<!--            <li>-->
<!--              <router-link class="dropdown-item" :to="{ name: 'administrator-profile', params: { id: user.userIdentifier }}">-->
<!--                <b-icon-people class="mr-2"></b-icon-people> Profile-->
<!--              </router-link>-->
<!--            </li>-->

            <li>
              <router-link @click="dropdown" class="dropdown-item" :to="{ name: 'profile', params: { id: 'fgdhdghdfh' }}">
                <b-icon-people class="mr-2"></b-icon-people> Profile
              </router-link>
            </li>
            <li>
              <a @click="logout()" class="dropdown-item">
                <b-icon icon="toggle-off" class="mr-2" aria-hidden="true"></b-icon> Logout
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <aside class="main-sidebar sidebar-dark-blue elevation-4">
      <a href="/vue" class="brand-link bg-info text-center">
        <span class="brand-text font-weight-bold pt-3 pb-3">
          Gold Rush
        </span>
      </a>
      <div class="sidebar">
        <nav class="mt-2">
          <ul data-widget="treeview" role="menu" data-accordion="false"
              class="nav nav-pills nav-sidebar nav-child-indent flex-column">
            <li class="nav-item first">
              <router-link
                  to="/"
                  class="nav-link"
              >
                <b-icon icon="clipboard-data" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.dashboard') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/app-config"
                  class="nav-link"
              >
                <b-icon icon="gear" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.app_config') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/clients"
                  class="nav-link"
              >
                <b-icon icon="people" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.clients') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/kyc"
                  class="nav-link"
              >
                <b-icon icon="people" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.kyc') }}</p>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link
                  to="/monthly-profit"
                  class="nav-link"
              >
                <b-icon icon="percent" class="mr-2" aria-hidden="true"></b-icon>
                <p>Monthly profit configuration</p>
              </router-link>
            </li>


            <li class="nav-item first">
              <router-link to="/cms" class="nav-link">
                <b-icon icon="file-text-fill" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.cms') }}</p>
              </router-link>
            </li>
            <li class="nav-item first">
              <router-link to="/mining-pool" class="nav-link">
                <b-icon icon="minecart-loaded" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.label.mining-pool') }}</p>
              </router-link>
            </li>
            <li class="nav-item first">
              <router-link to="/gold-bar-goal" class="nav-link">
                <b-icon icon="geo-alt" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.label.gold-bar-goal') }}</p>
              </router-link>
            </li>
            <li class="nav-item first">
              <router-link to="/gold-bar-goal/shipment" class="nav-link">
                <b-icon icon="geo-alt" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.label.gold-bar-goal-shipment') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/withdraw"
                  class="nav-link"
              >
                <b-icon icon="cash-stack" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.withdraw') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/deposit"
                  class="nav-link"
              >
                <b-icon icon="wallet2" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.deposit') }}</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  to="/turnover-rewards"
                  class="nav-link"
              >
                <b-icon icon="gift" class="mr-2" aria-hidden="true"></b-icon>
                <p>{{ $t('menu.turnover-rewards') }}</p>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
    <div class="content-wrapper">
      <div class="content">
        <div class="container-fluid">
          <router-view/>
        </div>
      </div>
    </div>
    <footer class="main-footer">
      <strong>Copyright © 2023 Gold Rush </strong>
    </footer>
    <aside class="control-sidebar control-sidebar-dark advanced-filters"></aside>
    <div id="sidebar-overlay"></div>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      dashboard: false,
      dropdown: false
    }
  },
  mounted() {
    if (!this.user) {
      this.$store.dispatch('root/fetchUser');
    }
    this.checkRoute(this.$route);
  },
  computed: {
    user: function () {
      return this.$store.getters["root/user"];
    }
  },
  methods: {
    collapseMenu() {
      const body = document.querySelector('body');
      body.classList.toggle('sidebar-collapse')
    },
    closeEvent: function () {
      this.dropdown = false;
    },
    checkRoute(route) {
      this.closeAll();
      switch (route.name) {
        case 'dashboard': {
          this.dashboard = true;
          break;
        }
      }
    },
    closeAll() {
      this.dashboard = false;
    },
    logout() {
      this.$store.dispatch('root/logout').then(() => {
        this.$router.push({name: 'login'})
      })
    }
  },
  watch: {
    $route() {
      this.checkRoute(this.$route);
    }
  }
}
</script>
