import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";
import dashboardStore from '../modules/dashboard/store';
import clientsStore from '../modules/clients/store/clientsStore';
import root from './root';
import cms from "@/modules/cms/store/index";
import monthlyProfitStore from "@/modules/monthly-profit/store/monthlyProfitStore";
import miningPoolStore from "@/modules/mining-pool/store/miningPoolStore";
import goldBarGoalStore from "@/modules/gold-bar-goal/store/goldBarGoalStore";
import shipmentStore from "@/modules/shipment/store/shipmentStore";
import turnoverRewardsStore from "@/modules/turnover-rewards/store/turnoverRewardsStore";
import translations from "@/modules/translations/store/index";
import kycStore from "@/modules/kyc/store/kycStore";
import withdrawStore from "@/modules/withdraw/store/withdrawStore";
import depositStore from "@/modules/deposit/store/depositStore";


Vue.prototype.$http = axios;
Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        root,
        dashboardStore,
        clientsStore,
        cms,
        translations,
        monthlyProfitStore,
        miningPoolStore,
        goldBarGoalStore,
        shipmentStore,
        kycStore,
        withdrawStore,
        depositStore,
        turnoverRewardsStore
    },
    strict: true,
    plugins: debug ? [] : []
});
