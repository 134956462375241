import axios from "axios";
import {createFilterQuery} from "@/main";

const state = () => ({
    items: [],
    busy: false,
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
});

// getters
const getters = {
    items: state => state.items,
    totalCount: state => state.totalCount,
    fields: state => state.fields,
    busy: state => state.busy
}

// mutations
const mutations = {
    setItems(state, response) {
        state.isBusy = false;
        state.items = response.data.results;
        state.totalCount = response.data.totalCount;
    },
    setCurrentPage(state, data) {
        state.currentPage = data;
    },
    setPerPage(state, data) {
        state.perPage = data;
    },
    setBusy(state, data) {
        state.busy = data;
    }

}

// actions
const actions = {
    // eslint-disable-next-line no-unused-vars
    new({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.post('/mining-pool/offer/create', {
                name: data.name,
                expiration: parseInt(data.expiration),
                activationTime: parseInt(data.activationTime),
                isStarterPack: data.isStarterPack,
                commissionsPercent: parseInt(data.commissionsPercent),
                commissionsLevel: parseInt(data.commissionsLevel),
                level: parseInt(data.level),
                status: data.status,
                price: parseInt(data.price),
            })
                .then((response) => {
                    commit('setBusy', false);
                    resolve(response);
                })
                .catch((error) => {
                    commit('setBusy', false);
                    reject(error);
                })
        })
    },
    // eslint-disable-next-line no-unused-vars
    update({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.post('/mining-pool/offer/'+data.id+'/update', {
                name: data.name,
                expiration: parseInt(data.expiration),
                activationTime: parseInt(data.activationTime),
                isStarterPack: data.isStarterPack,
                commissionsPercent: parseInt(data.commissionsPercent),
                commissionsLevel: parseInt(data.commissionsLevel),
                level: parseInt(data.level),
                status: data.status,
                price: parseInt(data.price),
            })
                .then((response) => {
                    commit('setBusy', false);
                    resolve(response);
                })
                .catch((error) => {
                    commit('setBusy', false);
                    reject(error);
                })
        })
    },
    loadItems({commit}, data) {
        axios.get(`/mining-pool/offers?limit=${data.params.limit}&offset=${data.params.offset}${createFilterQuery(data.filters)}`)
            .then(function (response) {
                commit('setItems', response);
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
