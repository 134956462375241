<template>
  <b-modal
      ref="two-factor-modal"
      size="lg"
      title="Enable two factor authorization"
  >
    <qrcode-vue :value="'otpauth://totp/'+admin.login+'?secret='+secret+'&issuer=GoldRush-Backoffice'" :size="300"
                level="H"/>

    <b-form-group label="Scan QR code or enter this secret key:">
      <strong>{{ secret }}</strong>
    </b-form-group>
    <b-form-group label="Verification code">
      <b-form-input
          v-model="form.code"
          placeholder="Verification code"
          required
          :state="!$v.form.code.$dirty ? null :(!$v.form.code.$error)"
      ></b-form-input>
    </b-form-group>

    <template #modal-footer>
      <div class="w-100">
        <div class="buttons float-right">
          <b-button type="button" variant="secondary" class="mr-1" @click="hideModal()">{{ $t('btn.cancel') }}
          </b-button>
          <b-button variant="primary" :disabled="busy" @click="submit()">
            <div v-if="busy">
              <b-spinner small type="grow"></b-spinner>
              {{ $t('btn.state.saving') }}
            </div>
            <div v-else>
              {{ $t('btn.save') }}
            </div>
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>

import {required} from "vuelidate/lib/validators";
import axios from "axios";
import QrcodeVue from 'qrcode.vue'
import alertify from "alertifyjs";

export default {
  components: {
    QrcodeVue
  },
  data() {
    return {
      secret: null,
      busy: false,
      admin: {
        login: null,
      },
      groups: [],
      serverError: null,
      form: {
        code: null
      },
    }
  },
  validations: {
    form: {
      code: {required},
    },
    secret: {required}
  },
  mounted() {
  },
  computed: {},
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = {
          "secret": this.secret,
          "input": this.form.code,
        }

        axios.post(`/admin-user/enable2fa`, data)
            .then(() => {
              this.hideModal();
              setTimeout(() => {
                this.$emit('reload-items')
              }, 500)
            })
            .catch(() => {
              alertify.error(this.$t('Invalid code'));
            })

      }
    },
    openModal(admin) {
      this.admin = admin;
      let that = this;
      axios.get(`/admin-user/get-2fa-secret`)
          .then(function (response) {
            that.secret = response.data.secret;
          });
      this.$refs['two-factor-modal'].show();
    },
    hideModal() {
      this.$refs['two-factor-modal'].hide();
    },
  }
}
</script>
