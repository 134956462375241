<template>
  <div class="card questions">
    <div class="card-header">
      <h3 class="card-title">{{ $t('page.title.admin_profile') }}</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <b-card v-if="admin">
            <b-card-header>General information</b-card-header>
            <b-card-body>
              <label>Id</label>
              <p>{{ admin.userIdentifier }}</p>
              <label>Username</label>
              <p>{{ admin.login }}</p>
              <label>2FA</label>
              <p>{{ admin.required2FA ? 'enabled' : 'disabled' }}
                <b-button @click="disable2FA()" size="xs" class="mr-1" variant="outline-danger"
                          v-if="admin.required2FA">
                  Disable 2FA
                </b-button>
                <b-button @click="enable2FA()" size="xs" class="mr-1" variant="outline-success"
                          v-if="!admin.required2FA">
                  Enable 2FA
                </b-button>
              </p>
            </b-card-body>
          </b-card>
        </div>
        <div class="col-4">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import Vue from "vue";
import TwoFactorModal from "@/modules/admin/containers/TwoFactorModal.vue";

export default {
  props: ['apiKey'],
  data() {
    return {
      secret: null,
      form: {
        code: null
      }
    };
  },
  mounted() {
  },
  computed: {
    admin: function () {
      return this.$store.getters["root/user"];
    },
  },
  methods: {
    disable2FA() {
      let that = this;
      axios.post(`/admin-user/disable2fa`)
          .then(function () {
            setTimeout(() => {
              that.$store.dispatch('root/fetchUser');
            }, 500)
          });
    },
    enable2FA() {
      const ModalConstructor = Vue.extend(TwoFactorModal);
      const modal = new ModalConstructor({parent: this});
      modal.$mount();
      modal.openModal(this.admin);
      modal.$on('reload-items', () => {
        this.$store.dispatch('root/fetchUser');
      });
    },
  },
  watch: {
    $route() {
    }
  }
}
</script>
