const TranslationsApp = () => import(/* webpackChunkName: "TranslationsGrid" */ './TranslationsApp');
const TranslationsGrid = () => import(/* webpackChunkName: "TranslationsGrid" */ './containers/TranslationsGrid');
const LabelTranslations = () => import(/* webpackChunkName: "LabelTranslations" */ './containers/LabelTranslations');

export const translationRouting = [{
    path: '/translations', component: TranslationsApp,
    children: [
        {path: '/', name: 'translations', component: TranslationsGrid},
        {path: ':id', name: 'translations-details', component: LabelTranslations},
    ]
}];
