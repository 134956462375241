import axios from "axios";
import {createFilterQuery} from "@/main";
import alertify from "alertifyjs";

const state = () => ({
    items: [],
    busy: false,
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
    client: null,
    wallet: null,
    walletId: null,
    goldBarGoal: null,
    turnover: null,
});

// getters
const getters = {
    items: state => state.items,
    turnover: state => state.turnover,
    totalCount: state => state.totalCount,
    fields: state => state.fields,
    client: state => state.client,
    wallet: state => state.wallet,
    goldBarGoal: state => state.goldBarGoal,
    walletId: state => state.walletId,
    busy: state => state.busy
}

// mutations
const mutations = {
    setItems(state, response) {
        state.isBusy = false;
        state.items = response.data.results;
        state.totalCount = response.data.totalCount;
    },
    setClient(state, response) {
        if (response == null) {
            state.client = null;
        } else {
            state.client = response.data;
        }
    },
    setGoldBarGoal(state, response) {
        if (response !== null){
            state.goldBarGoal = response.data;
        }else{
            state.goldBarGoal = null;
        }
    },
    setTurnover(state, response) {
        if (response !== null){
            state.turnover = response.data;
        }else{
            state.turnover = null;
        }
    },
    setWallet(state, response) {
        if (response == null) {
            state.wallet = null;
        } else {
            state.walletId = response.data.id;
            state.wallet = response.data;
        }
    },
    setCurrentPage(state, data) {
        state.currentPage = data;
    },
    setPerPage(state, data) {
        state.perPage = data;
    },
    setBusy(state, data) {
        state.busy = data;
    }

}

// actions
const actions = {
    // eslint-disable-next-line no-unused-vars
    manualDeposit({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.post('/deposit-transaction/manual', {
                clientAccountId: data.clientId,
                address: data.address.toString(),
                network: data.network.toString(),
                txid: data.txid.toString(),
                symbol: data.symbol.toString(),
                value: data.value.toString(),
            })
                .then((response) => {
                    commit('setBusy', false);
                    resolve(response);
                })
                .catch((error) => {
                    commit('setBusy', false);
                    reject(error);
                })
        })
    },
    // eslint-disable-next-line no-unused-vars
    changeUsername({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.post(`/client/${data.id}/change-username`, {
                username: data.username.toString()
            })
                .then((response) => {
                    commit('setBusy', false);
                    resolve(response);
                })
                .catch((error) => {
                    commit('setBusy', false);
                    if (error.response.status !== 200){
                        alertify.error('Error: ' + error.response.data.label);
                    }
                    reject(error);
                })
        })
    },
    // eslint-disable-next-line no-unused-vars
    changeEmail({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.post(`/client/${data.id}/change-email`, {
                email: data.email.toString()
            })
                .then((response) => {
                    commit('setBusy', false);
                    resolve(response);
                })
                .catch((error) => {
                    commit('setBusy', false);
                    if (error.response.status !== 200){
                        alertify.error('Error: ' + error.response.data.label);
                    }
                    reject(error);
                })
        })
    },
    // eslint-disable-next-line no-unused-vars
    changeSponsor({commit}, data) {
        return new Promise((resolve, reject) => {
            axios.post(`/client/${data.id}/change-sponsor`, {
                sponsor: data.sponsor.toString()
            })
                .then((response) => {
                    commit('setBusy', false);
                    resolve(response);
                })
                .catch((error) => {
                    commit('setBusy', false);
                    if (error.response.status !== 200){
                        alertify.error('Error: ' + error.response.data.label);
                    }
                    reject(error);
                })
        })
    },
    // eslint-disable-next-line no-unused-vars
    manualMoneyCorrection({commit}, data) {
        axios.post(`/wallet/${data.walletId}/manual-money-correction`, {
            description: data.description.toString(),
            correction: data.correction.toString(),
        })
            .then(() => {
                commit('setBusy', false);
            })
            .catch(() => {
                commit('setBusy', false);
            })
    },
    // eslint-disable-next-line no-unused-vars
    manualGoldCorrection({commit}, data) {
        axios.post(`/wallet/${data.walletId}/manual-gold-correction`, {
            description: data.description.toString(),
            correction: data.correction.toString(),
        })
            .then(() => {
                commit('setBusy', false);
            })
            .catch(() => {
                commit('setBusy', false);
            })
    },
    loadItems({commit}, data) {
        axios.get(`/client/list?limit=${data.params.limit}&offset=${data.params.offset}${createFilterQuery(data.filters)}`)
            .then(function (response) {
                commit('setItems', response);
            })
    },
    getClient({commit}, data) {
        axios.get(`/client/${data}/details`)
            .then(function (response) {
                commit('setClient', response);
            })
    },
    getWallet({commit}, data) {
        axios.get(`/client/${data}/wallet`)
            .then(function (response) {
                commit('setWallet', response);
            })
    },
    getTurnover({commit}, data) {
        axios.get(`/client/${data}/turnover`)
            .then(function (response) {
                commit('setTurnover', response);
            })
    },
    getGoldBarsGoal({commit}, data) {
        axios.get(`/wallet/${data}/gold-bar-goal`)
            .then(function (response) {
                console.log('then');
                commit('setGoldBarGoal', response);
            })
            .catch(function(){
                console.log('catch');
                commit('setGoldBarGoal', null);
            })
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
