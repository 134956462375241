import axios from "axios";
import alertify from "alertifyjs";

const state = () => ({
    isBusy: false,
    domainGroups: [],
    createLabelDomainGroups: [],
    locale: [],
    labelTranslation: null
})

// getters
const getters = {
    isBusy: state => state.isBusy,
    domainGroups: state => state.domainGroups,
    createLabelDomainGroups: state => state.createLabelDomainGroups,
    locale: state => state.locale,
    labelTranslation: state => state.labelTranslation,

}

// actions
const actions = {
    // eslint-disable-next-line no-unused-vars
    deleteLabel({commit, dispatch}, key) {
        return new Promise((resolve, reject) => {
            axios.delete(`/translation-row/${key}`)
                .then(resp => {
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    importLabels({commit, dispatch}, form) {
        commit('setBusy', true);
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append('translations', form.file);
            formData.append('options', JSON.stringify({locale: form.locale, createKeys: form.createKeys}));


            axios.post(`/translations/import`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
                .then(resp => {
                    resolve(resp);
                    commit('setBusy', false);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    exportLabels({commit, dispatch}, form) {
        commit('setBusy', true);
        return new Promise((resolve, reject) => {
            axios.post(`/translations/export`, form)
                .then(resp => {
                    resolve(resp);
                    commit('setBusy', false);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    postLabelGroup({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            axios.post(`/label/${data.id}/domain-group`, data.payload)
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    getLabelDetail({commit, dispatch}, key) {
        return new Promise((resolve, reject) => {
            axios.get(`/label/details/${key}`)
                .then(resp => {
                    commit('setBusy', false);
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getLabelGroup(id) {
        return new Promise((resolve, reject) => {
            axios.get(`/label/${id}/domain-group`)
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    splitGroup({commit, dispatch}, id) {
        return new Promise((resolve, reject) => {
            axios.put(`/label/${id}/domain-group/split`)
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    generateTranslation({commit, dispatch}, id) {
        return new Promise((resolve, reject) => {
            axios.get(`/generator/${id}/generate`)
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    getGeneratorGrid({commit, dispatch}) {
        return new Promise((resolve, reject) => {
            axios.get(`/generator?limit=100`)
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getTranslationGrid({commit}, label) {
        return new Promise((resolve, reject) => {
            axios.get(`/label/${label}`)
                .then(resp => {
                    if (resp.data.results && resp.data.results.length) {
                        let reqArr = [];
                        resp.data.results.forEach(l => {
                            reqArr.push(axios.get(`/label/${l.id}/domain-group?idd=${l.id}`));
                            reqArr.push(axios.get(`/label/${l.id}/translation?idd=${l.id}`));
                        });

                        axios.all(reqArr)
                            .then(
                                axios.spread((...responses) => {
                                    responses.forEach(res => {
                                        const id = res.config.url.split('idd=');
                                        if (res.config.url.indexOf('translation') > -1) {
                                            commit('setTranslation', {id: id[1], translation: res.data.results})
                                        } else {
                                            commit('setTranslation', {id: id[1], groups: res.data.results})
                                        }

                                    })
                                    resolve(responses);
                                })
                            )
                            .catch(errors => {
                                // react on errors.
                                reject(errors)
                            });
                    }
                    resolve(null);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    postLabel({state}, label) {
        return new Promise((resolve, reject) => {
            axios.post(`/label`, {key: label})
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    postLabelTranslations({state, commit, dispatch}, translations) {
        commit('setBusy', true);
        return new Promise((resolve, reject) => {
            axios.post(`/label/update/translations`, {translations: translations})
                .then(resp => {
                    alertify.success('Translations saved.');
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    postLocale({state, commit, dispatch}, payload) {
        commit('setBusy', true);
        return new Promise((resolve, reject) => {
            axios.post(`/locale`, payload)
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    putLocale({state, commit, dispatch}, payload) {
        commit('setBusy', true);
        return new Promise((resolve, reject) => {
            axios.put(`/locale/${payload.id}`, payload)
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    deleteLocale({state, commit, dispatch}, id) {
        commit('setBusy', true);
        return new Promise((resolve, reject) => {
            axios.delete(`/locale/${id}`)
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    checkLabel({commit}, label) {
        return new Promise((resolve, reject) => {
            axios.get(`/translation-row?limit=1&offset=0&filters[key][0][value]=${label}`)
                .then(resp => {
                    if (resp.data.results.length) {
                        const domains = Object.values(resp.data.results[0].domainGroups);
                        commit('setCreateLabelDomainGroups', domains);
                    } else {
                        commit('setCreateLabelDomainGroups', []);
                    }
                    resolve(true);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getDomainGroups({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(`/domain-group`)
                .then(resp => {
                    if (resp.data.results.length) {
                        commit('setDomainGroups', resp.data.results);
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getLocale({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(`/locale`)
                .then(resp => {
                    if (resp.data.results.length) {
                        commit('setLocale', resp.data.results);
                    }

                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
}

// mutations
const mutations = {
    setBusy(state, data) {
        state.isBusy = data;
    },
    setDomainGroups(state, data) {
        state.domainGroups = data;
    },
    setCreateLabelDomainGroups(state, data) {
        let gr = [];
        state.domainGroups.forEach(g => {
            const f = data.find(gg => gg.id === g.id);
            if (!f) {
                gr.push(g);
            }
        })
        state.createLabelDomainGroups = gr;
    },
    setLocale(state, data) {
        state.locale = data
    },
    setTranslation(state, data) {
        if (!state.labelTranslation) {
            state.labelTranslation = {};
        }
        state.labelTranslation[data.id] = {...state.labelTranslation[data.id], ...data}
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
