const ClientDetails = () => import(/* webpackChunkName: "PlayerOverview" */ './containers/ClientDetails.vue');
const ClientsList = () => import(/* webpackChunkName: "PlayerList" */ './containers/ClientsGrid.vue');


export const clientsRouting = [
    {path: '/clients', name: 'clients-list', component: ClientsList},
    {
        path: '/client/:id', component: ClientDetails, name: 'client-details'
    },
]
