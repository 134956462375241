import axios from "axios";

const state = () => ({
    goldPriceAmount: null,
    goldPriceDate: null,
});

// getters
const getters = {
    goldPriceAmount: state => state.goldPriceAmount,
    goldPriceDate: state => state.goldPriceDate,
}

// mutations
const mutations = {
    setGoldPrice(state, response) {
        state.goldPriceAmount = response.data.price;
        state.goldPriceDate = response.data.date;
    },
}

// actions
const actions = {
    loadGoldPrice({commit}) {
        axios.get(`/gold-price/actual`)
            .then(function (response) {
                commit('setGoldPrice', response);
            })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
