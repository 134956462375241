import axios from "axios";
// import {loadLanguageAsync} from "@/main";
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;


const state = () => ({
    apiKey: null,
    loading: false,
    loading2FA: false,
    isRequired2FA: false,
    user: null,
    filterStore: {
        clients: null
    },
    locale: [],
})

// getters
const getters = {
    apiKey: state => state.apiKey,
    loading: state => state.loading,
    loading2FA: state => state.loading2FA,
    user: state => state.user,
    isRequired2FA: state => state.isRequired2FA,
    filter: state => name => {
        return state.filterStore[name];
    },
    locale: state => state.locale,//.filter( l => l.status !== 'DELETED'),
    adminLocale: state => state.locale.filter(l => l.domainGroup === 'admin'),
}

// actions
const actions = {
    fetchUser({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(`/admin-user`)
                .then(resp => {
                    commit('setUser', resp.data);
                    // localStorage.setItem('defaultLocale', resp.data.profile.locale);
                    // loadLanguageAsync(resp.data.profile.locale, false);
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        })
    },
    login({commit, dispatch}, payload) {
        commit('setLoader', true);
        return new Promise((resolve, reject) => {
            axios.post(`/admin-user/login`, payload)
                .then(resp => {
                    if (resp.data.twoFaRequired === false) {
                        commit('setIsRequired2FA', false);
                        commit('setApiKey', resp.data.authToken);
                        localStorage.setItem('apiKey', resp.data.authToken);
                        dispatch('fetchUser').then(
                            () => {
                                console.log('fetch user');
                                commit('setLoader', false);
                                resolve(resp.data);
                            }
                        )
                        return;
                    }
                    commit('setIsRequired2FA', true);
                    commit('setApiKey', resp.data.authToken);
                    localStorage.setItem('apiKey', resp.data.authToken);
                })
                .catch(err => {
                    reject(err);
                });
        })
    },
    login2fa({commit, dispatch}, payload) {
        commit('setLoader', true);
        return new Promise((resolve, reject) => {
            axios.post(`/admin-user/login-2fa`,  payload)
                .then(resp => {
                    commit('setApiKey', resp.data.authToken);
                    localStorage.setItem('apiKey', resp.data.authToken);
                    dispatch('fetchUser').then(
                        () => {
                            commit('setLoader', false);
                            commit('setIsRequired2FA', false);
                            resolve(resp.data);
                        }
                    )
                })
                .catch(err => {
                    reject(err);
                });
        })
    },
    // eslint-disable-next-line no-unused-vars
    logout({commit, dispatch}, payload) {
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            axios.post(`/admin-user/logout`)
                .then(resp => {
                    localStorage.removeItem('apiKey');
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                });
        })
    },
    // eslint-disable-next-line no-unused-vars
    getLocale({state, commit}, dict) {
        return new Promise((resolve, reject) => {
            axios.get(`/locale`)
                .then(resp => {
                    commit('setLocale', resp.data.results);
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
}

// mutations
const mutations = {
    setApiKey(state, data) {
        state.apiKey = data;
        axios.defaults.headers.common['X-AUTH-TOKEN'] = data;
    },
    setUser(state, data) {
        state.user = data;
    },
    setIsRequired2FA(state, data) {
        state.isRequired2FA = data;
    },
    setLoader2FA(state, data) {
        state.loading2FA = data;
    },
    setLoader(state, data) {
        state.loading = data;
    },
    setFilter(state, data) {

        if (data.operator) {
            state.filterStore[data.storeName][data.filterName] = data;
        } else {
            state.filterStore[data.storeName][data.filterName] = data.value;
        }
    },
    initFilters(state, data) {
        state.filterStore[data.storeName] = {};
        data.filterNames.forEach(s => {
            state.filterStore[data.storeName][s] = null;
        })
    },
    setLocale(state, data) {
        state.locale = data;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
