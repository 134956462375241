import axios from "axios";
import moment from 'moment';
import './styles.scss';
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            defaultFilters: '',
            gridGetUrl: '/dummy/url',
            isBusy: false,
            showDouble: [],
            selectedRows: [],
            perPage: 10,
            currentPage: 1,
            totalCount: 0,
            orderColumn: null,
            orderDirection: false,
            bulkActions: [],
            bulkAction: null,
            filterQuery: [],
            items: [],
            rowIndexToScroll: null,
            additionalSort: null
        };
    },
    filters: {
        date: function (value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD HH:mm:ss') || "";
            }
        }
    },
    mounted() {
        axios.defaults.headers.common['X-AUTH-TOKEN'] = localStorage.getItem('apiKey');

        if (this.gridGetUrl && this.defaultFilters) {
            this.loadItems(this.defaultFilters);
        } else if (this.gridGetUrl) {
            this.loadItems();
        }
        this.$on('refreshGrid', (currentPage) => {
            this.applyFilters(currentPage);
        });

        this.$on('setRowIndexToScroll', (rowIndex) => {
            this.rowIndexToScroll = rowIndex;
        });

        document.addEventListener('keydown', this.bindEnter);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.bindEnter);
    },
    methods: {
        scrollToRow() {
            if(this.rowIndexToScroll) {
                setTimeout(() => {
                    const tbody = this.$refs.table.$el.querySelector('tbody');
                    const row = tbody.querySelectorAll('tr')[this.rowIndexToScroll];
                    row.scrollIntoView();
                    this.rowIndexToScroll = null;
                }, 100);
            }
        },
        bindEnter(e) {
            if (e.keyCode === 13) {
                this.applyFilters();
            }
        },
        getDataWithPromise(url) {
            return new Promise((resolve, reject) => {
                axios.get(url)
                    .then(resp => {
                        resolve(resp.data);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        getData: function (url, localStorage) {
            let _that = this;
            axios.get(url)
                .then((resp) => {
                    _that[localStorage[0]][localStorage[1]] = resp.data.results;
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        resetFilters() {
            this.$root.$emit('resetFilters');
            this.filterQuery = [];
            this.applyFilters();
        },
        createSortQuery() {
            let sorting;
            let sortString = '';
            if (this.orderColumn) {
                if (this.orderDirection === true) {
                    sorting = encodeURIComponent('+' + this.orderColumn);
                } else {
                    sorting = encodeURIComponent('-' + this.orderColumn);
                }
                sortString = '&sort[]=' + sorting;
            } else {
                sortString = '';
            }

            if (this.additionalSort) {
                sortString = sortString + '&sort[]='+this.additionalSort
            }

            return sortString;
        },
        createFilterQuery() {
            let filter = '';

            Object.keys(this.filterQuery).forEach(key => {
                filter += this.filterQuery[key];

                if (key === 'phrase') {
                    filter = filter.replace('&filters[phrase][0][operator]=eq', '');
                    filter += '&filters[phrase][0][operator]=like'
                }
            })

            return filter;
        },
        applyFilters(currentPage) {
            this.currentPage = currentPage ? currentPage : 1;

            if (this.gridGetUrl && this.defaultFilters && !this.filterQuery.status) {
                this.loadItems(this.defaultFilters);
            } else if (this.gridGetUrl) {
                this.loadItems();
            }
        },
        paginationReload(data) {
            this.currentPage = data.currentPage;
            this.perPage = data.perPage;
            this.totalCount = data.totalCount;
            this.loadItems();
        },
        loadItems(defaultFilters) {
            this.isBusy = true;
            let _that = this;
            let offset = this.perPage * (this.currentPage - 1);
            let fullUrl = this.gridGetUrl + '?limit='
                + this.perPage
                + '&offset=' + offset
                + this.createSortQuery() + this.createFilterQuery();
            if (defaultFilters && typeof defaultFilters === 'string') {
                fullUrl = fullUrl + defaultFilters;
            }

            axios.get(fullUrl)
                .then(function (response) {
                    if (response.data && response.data.results) {
                        _that.items = response.data.results;
                    } else {
                        _that.items = response.data;
                    }

                    _that.totalCount = response.data.totalCount;
                    _that.isBusy = false;
                    _that.scrollToRow();
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
    watch: {
        orderColumn: function () {
            this.loadItems(this.defaultFilters);
        },
        orderDirection: function () {
            this.loadItems(this.defaultFilters);
        }
    },
    computed: {
        userRoles: function () {
            return this.$store.getters.getUserRoles;
        },
        apiKey: function () {
            return this.$store.getters["root/apiKey"];
        }
    }
});
