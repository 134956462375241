import axios from 'axios';
import { saveAs } from 'file-saver';

export default {
    async getAvailableParents({ commit, state }, locale) {
       const { data } = await axios.get(`/resource/parents/${state.group}/${locale}`);
       commit('SET_AVAILABLE_PARENTS', data);
       return data;
    },
    async getLocale({ commit }) {
        const { data } = await axios.get(`/locale`);
        const filteredLocale = data.results.filter(locale => locale.applicationName === process.env.VUE_APP_FRONT_GROUP_NAME);
        commit('SET_LOCALE_LIST', filteredLocale);
        return data;
    },
    async getResourceStructure({ commit, state }) {
        const { data } = await axios.get(`/resource/structure/${state.group}/${state.locale}`);
        commit('SET_RESOURCE_STRUCTURE', data);
        return data;
    },
    async getResourceDetails({ commit }, id) {
        commit('SET_IS_LOADING', true);
        const { data } = await axios.get(`/resource/${id}`);
        // eslint-disable-next-line no-unused-vars
        const { lft, lvl, rgt, ...parsedResponse } = data;

        commit('SET_RESOURCE_DETAILS', parsedResponse);
        commit('SET_ACTIVE_RESOURCE_PARENT_ID', data.parentId);
        commit('SET_IS_LOADING', false);
        return data;
    },
    async getResourceFile({ commit }, id) {
        const { data } = await axios.get(`/resource/${id}/file`);
        const file = data.length === 0 ? null : data;
        commit('SET_RESOURCE_FORM_FILE', file);
        return data;
    },
    async getResourceTranslations({ commit }, id) {
        commit('SET_IS_LOADING', true);
        const { data } = await axios.get(`/resource/translations/${id}`);
        commit('SET_RESOURCE_TRANSLATIONS', data);
        commit('SET_IS_LOADING', false);
        return data;
    },
    async getResourceChildElements({ commit }, id) {
        commit('SET_IS_LOADING', true);
        const { data } = await axios.get(`/resource/children/${id}`);
        commit('SET_RESOURCE_CHILD_ELEMENTS', data);
        commit('SET_IS_LOADING', false);
        return data;
    },
    async createResource(context, payload) {
        const { data } = await axios.post(`/resource/create`, payload);
        return data;
    },
    async updateResource(context, payload) {
        const { data } = await axios.post(`/resource/update`, payload);
        return data;
    },
    async deleteResource(context, id) {
        const payload = { id: Number(id) };
        const { data } = await axios.post(`/resource/delete`, payload);
        return data;
    },
    async uploadResourceFile(context, payload) {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        const { data } = await axios.post( `/resource/file/upload`, payload, config);
        return data;
    },
    async deleteResourceFile(context, id) {
        const numId = {
            id: Number(id)
        };
        const { data } = await axios.post( `/resource/file/delete`, numId);
        return data;
    },
    async moveResource(context, payload) {
        const { data } = await axios.post( `/resource/move`, payload);
        return data;
    },
    async importCmsToXml(context, payload) {
        let formData = new FormData();

        formData.append('locale', payload.locale);

        if(payload.id) {
            formData.append('id', payload.id);
        }

        formData.append('file', payload.file);

        await axios.post( `/resource/import`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    },
    async exportCmsToXml(context, payload) {
        await axios.post(`/resource/export`, payload)
            .then((response) => {
                const headerLine = response.headers['content-disposition'];
                const startFileNameIndex = headerLine.indexOf('"') + 1
                const endFileNameIndex = headerLine.lastIndexOf('"');
                const filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

                const blob = new Blob([response.data], {type: "text/xml"});
                saveAs(blob, filename);
            });
    },
};